<template>
    <g>
        <mask id="path-like-inside-1" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.446 6.13717L5.99722 10.9459L1.39018 5.96614L1.39393 5.96209C0.839804 5.32892 0.5 4.47433 0.5 3.53396C0.5 1.58221 1.96378 0 3.76945 0C4.63269 0 5.41779 0.361616 6.00217 0.952348C6.58654 0.361616 7.37164 0 8.23488 0C10.0405 0 11.5043 1.58221 11.5043 3.53396C11.5043 4.56406 11.0966 5.49123 10.446 6.13717Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.446 6.13717L5.99722 10.9459L1.39018 5.96614L1.39393 5.96209C0.839804 5.32892 0.5 4.47433 0.5 3.53396C0.5 1.58221 1.96378 0 3.76945 0C4.63269 0 5.41779 0.361616 6.00217 0.952348C6.58654 0.361616 7.37164 0 8.23488 0C10.0405 0 11.5043 1.58221 11.5043 3.53396C11.5043 4.56406 11.0966 5.49123 10.446 6.13717Z" :fill="fill"/>
        <path d="M10.446 6.13717L9.74145 5.42755L9.72639 5.4425L9.71199 5.45807L10.446 6.13717ZM5.99722 10.9459L5.26317 11.625L5.99722 12.4184L6.73126 11.625L5.99722 10.9459ZM1.39018 5.96614L0.656151 5.28702L0.0278401 5.96612L0.656132 6.64525L1.39018 5.96614ZM1.39393 5.96209L2.12795 6.64121L2.73908 5.98068L2.14644 5.30351L1.39393 5.96209ZM6.00217 0.952348L5.29125 1.65562L6.00217 2.37427L6.71309 1.65562L6.00217 0.952348ZM9.71199 5.45807L5.26317 10.2668L6.73126 11.625L11.1801 6.81627L9.71199 5.45807ZM6.73126 10.2668L2.12422 5.28704L0.656132 6.64525L5.26317 11.625L6.73126 10.2668ZM2.1242 6.64527L2.12795 6.64121L0.659905 5.28296L0.656151 5.28702L2.1242 6.64527ZM2.14644 5.30351C1.75183 4.85261 1.5 4.23203 1.5 3.53396H-0.5C-0.5 4.71664 -0.0722229 5.80523 0.641415 6.62066L2.14644 5.30351ZM1.5 3.53396C1.5 2.05981 2.5879 1 3.76945 1V-1C1.33967 -1 -0.5 1.1046 -0.5 3.53396H1.5ZM3.76945 1C4.34397 1 4.87893 1.23882 5.29125 1.65562L6.71309 0.249075C5.95664 -0.515592 4.9214 -1 3.76945 -1V1ZM6.71309 1.65562C7.1254 1.23882 7.66036 1 8.23488 1V-1C7.08293 -1 6.04769 -0.515591 5.29125 0.249075L6.71309 1.65562ZM8.23488 1C9.41643 1 10.5043 2.05981 10.5043 3.53396H12.5043C12.5043 1.1046 10.6647 -1 8.23488 -1V1ZM10.5043 3.53396C10.5043 4.29889 10.202 4.97024 9.74145 5.42755L11.1506 6.84679C11.9912 6.01221 12.5043 4.82924 12.5043 3.53396H10.5043Z"  mask="url(#path-like-inside-1)"/>
    </g>
</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            default: 'white'
        }
    }
}
</script>